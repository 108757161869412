import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState('Processing your payment...');

  useEffect(() => {
    const updateWallet = async () => {
      const params = new URLSearchParams(location.search);
      const sessionId = params.get('session_id');
      
      if (!sessionId) {
        setMessage('Error: No session ID found.');
        return;
      }

      try {
        const response = await axios.post('/api/update-wallet', { sessionId }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        
        setMessage(`Payment successful! Your new balance is RM ${response.data.balance.toFixed(2)}`);
      } catch (error) {
        console.error("Error updating wallet:", error);
        setMessage('Error updating wallet. Please check your balance later.');
      }

      setTimeout(() => navigate('/wallet'), 3000);
    };

    updateWallet();
  }, [navigate, location]);

  return (
    <div>
      <h1>Payment Successful!</h1>
      <p>{message}</p>
      <p>Redirecting to your wallet...</p>
    </div>
  );
};

export default SuccessPage;