import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWallet,
  faUser,
  faList,
  faPlus,
  faHome,
  faChevronLeft,
  faChevronRight,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import logo from './icons/keboon-logo-white.png';

const BOONS_CONVERSION_RATE = 100; // 1 RM = 100 Boons
const TRANSACTIONS_PER_PAGE = 10;
const MIN_BOONS = 300; // Minimum 300 Boons (RM 3)
const MAX_BOONS = 1000000; // Maximum 1,000,000 Boons (RM 10,000)

const WalletScreen = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState("");
  const [rmCost, setRmCost] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [topUpCode, setTopUpCode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showConversionInfo, setShowConversionInfo] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const { data } = await axios.get('/api/config');
        const publishableKey = data.publishableKey;
        setStripePromise(loadStripe(publishableKey));
      } catch (error) {
        console.error('Error fetching Stripe config:', error);
        setError('Failed to initialize payment system. Please try again later.');
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('payment_status') === 'success') {
      let attempts = 0;
      const maxAttempts = 10;

      const pollInterval = setInterval(() => {
        fetchWalletDetails();
        attempts++;

        if (attempts >= maxAttempts) {
          clearInterval(pollInterval);
        }
      }, 2000);

      return () => clearInterval(pollInterval);
    } else {
      fetchWalletDetails();
    }
  }, []);

  const fetchWalletDetails = async () => {
    try {
      setIsLoading(true);
      const timestamp = new Date().getTime();
      const response = await axios.get(`/api/wallet?_t=${timestamp}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      
      setBalance(response.data.balance * BOONS_CONVERSION_RATE);
      
      const sortedTransactions = response.data.transactions.map(transaction => ({
        ...transaction,
        amount: transaction.amount * BOONS_CONVERSION_RATE
      })).sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      
      setTransactions(sortedTransactions);
      setTotalPages(Math.ceil(sortedTransactions.length / TRANSACTIONS_PER_PAGE));
      setError(null);
    } catch (error) {
      console.error("Error fetching wallet details:", error);
      setError("Failed to fetch wallet details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAmountChange = (e) => {
    const boonsAmount = e.target.value;
    
    // Remove any decimal places
    const wholeBoons = Math.floor(parseFloat(boonsAmount) || 0);
    
    if (wholeBoons > MAX_BOONS) {
      setAmount(MAX_BOONS.toString());
      setRmCost(MAX_BOONS / BOONS_CONVERSION_RATE);
    } else {
      setAmount(wholeBoons ? wholeBoons.toString() : "");
      setRmCost(wholeBoons / BOONS_CONVERSION_RATE);
    }
  };

  const handleTopUp = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage("");
    setIsLoading(true);
  
    const boonsAmount = parseInt(amount);
    
    if (!boonsAmount || isNaN(boonsAmount) || boonsAmount <= 0) {
      setError('Please enter a valid amount of Boons.');
      setIsLoading(false);
      return;
    }

    if (boonsAmount < MIN_BOONS) {
      setError(`Minimum top-up amount is ${MIN_BOONS} Boons (RM ${MIN_BOONS/BOONS_CONVERSION_RATE})`);
      setIsLoading(false);
      return;
    }

    if (boonsAmount > MAX_BOONS) {
      setError(`Maximum top-up amount is ${MAX_BOONS.toLocaleString()} Boons (RM ${MAX_BOONS/BOONS_CONVERSION_RATE})`);
      setIsLoading(false);
      return;
    }
  
    try {
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripe failed to initialize');
      }
  
      const response = await axios.post('/api/create-checkout-session',
        { amount: rmCost },
        { 
          headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` },
        }
      );
  
      if (!response.data || !response.data.sessionId) {
        throw new Error(`Invalid response from server: ${JSON.stringify(response.data)}`);
      }
  
      const result = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId
      });
  
      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error details:', error);
      setError(`Failed to initiate top-up: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTopUpCodeChange = (e) => {
    setTopUpCode(e.target.value);
  };

  const handleApplyTopUpCode = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage("");
    setIsLoading(true);

    try {
      const response = await axios.post('/api/apply-topup-code', 
        { code: topUpCode },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` } }
      );

      setSuccessMessage(response.data.message);
      setBalance(response.data.newBalance * BOONS_CONVERSION_RATE);
      setTopUpCode("");
      fetchWalletDetails();
    } catch (error) {
      console.error('Error applying top-up code:', error);
      setError(error.response?.data?.message || 'Failed to apply top-up code');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const getCurrentPageTransactions = () => {
    const startIndex = (currentPage - 1) * TRANSACTIONS_PER_PAGE;
    const endIndex = startIndex + TRANSACTIONS_PER_PAGE;
    return transactions.slice(startIndex, endIndex);
  };

  const formatNumber = (num) => {
    return num.toLocaleString('en-US');
  };

  return (
    <PageWrapper>
      <Header>
        <Logo src={logo} alt="Keboon Logo" />
        <Subtitle>Wallet</Subtitle>
      </Header>
      <MainContainer>
        <BalanceDisplay>
          Balance: {formatNumber(balance)} Boons
        </BalanceDisplay>

        <TopUpSection>
          <SectionTitle>
            Top Up Wallet
            <InfoIcon 
              icon={faInfoCircle} 
              onMouseEnter={() => setShowConversionInfo(true)}
              onMouseLeave={() => setShowConversionInfo(false)}
            />
          </SectionTitle>
          
          {showConversionInfo && (
            <ConversionInfoBox>
              <strong>Conversion Rate:</strong>
              <br />1 RM = 100 Boons
              <br />Minimum: {formatNumber(MIN_BOONS)} Boons (RM {MIN_BOONS/BOONS_CONVERSION_RATE})
              <br />Maximum: {formatNumber(MAX_BOONS)} Boons (RM {MAX_BOONS/BOONS_CONVERSION_RATE})
            </ConversionInfoBox>
          )}

          <Form onSubmit={handleTopUp}>
            <InputLabel>How many Boons would you like to top up?</InputLabel>
            <InputWrapper>
              <CurrencyInputContainer>
                <AmountInput
                  type="number"
                  value={amount}
                  onChange={handleAmountChange}
                  placeholder={`Min: ${formatNumber(MIN_BOONS)} Boons`}
                  min={MIN_BOONS}
                  max={MAX_BOONS}
                  id="topUpAmount"
                  name="topUpAmount"
                  autoComplete="off"
                />
                <CurrencyLabel>Boons</CurrencyLabel>
              </CurrencyInputContainer>
            </InputWrapper>
            
            {amount && (
              <CostPreview>
                Payment amount: RM {rmCost.toFixed(2)}
              </CostPreview>
            )}

            <TopUpButton type="submit" disabled={isLoading || !amount || amount < MIN_BOONS}>
              {isLoading ? (
                "Processing..."
              ) : !amount ? (
                "Enter amount to continue"
              ) : amount < MIN_BOONS ? (
                `Minimum ${MIN_BOONS} Boons required`
              ) : (
                `Top Up ${formatNumber(parseInt(amount))} Boons`
              )}
            </TopUpButton>
          </Form>

          <Divider>or</Divider>

          <Form onSubmit={handleApplyTopUpCode}>
            <InputLabel>Have a top-up code?</InputLabel>
            <InputWrapper>
              <AmountInput
                type="text"
                value={topUpCode}
                onChange={handleTopUpCodeChange}
                placeholder="Enter your top-up code"
                id="topUpCode"
                name="topUpCode"
              />
            </InputWrapper>
            <TopUpButton type="submit" disabled={isLoading || !topUpCode}>
              {isLoading ? "Processing..." : "Apply Code"}
            </TopUpButton>
          </Form>
        </TopUpSection>

        {error && <ErrorMessage>{error}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
        
        <TransactionHistory>
          <SectionTitle>Transaction History</SectionTitle>
          {getCurrentPageTransactions().length > 0 ? (
            getCurrentPageTransactions().map((transaction, index) => (
              <Transaction key={index}>
                <TransactionDescription>{transaction.description}</TransactionDescription>
                <TransactionAmount positive={transaction.amount > 0}>
                  {transaction.amount > 0 ? "+" : "-"}{" "}
                  {formatNumber(Math.abs(Math.floor(transaction.amount)))} Boons
                </TransactionAmount>
                <TransactionDate>{new Date(transaction.date).toLocaleString()}</TransactionDate>
              </Transaction>
            ))
          ) : (
            <NoTransactions>No transactions yet.</NoTransactions>
          )}
          {transactions.length > TRANSACTIONS_PER_PAGE && (
            <Pagination>
              <PageButton 
                onClick={() => handlePageChange(currentPage - 1)} 
                disabled={currentPage === 1}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </PageButton>
              <PageInfo>Page {currentPage} of {totalPages}</PageInfo>
              <PageButton 
                onClick={() => handlePageChange(currentPage + 1)} 
                disabled={currentPage === totalPages}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </PageButton>
            </Pagination>
          )}
        </TransactionHistory>

        <BottomNavigation>
          <NavItem to="/main">
            <NavIcon>
              <FontAwesomeIcon icon={faHome} />
            </NavIcon>
            Home
          </NavItem>
          <NavItem to="/add-product">
            <NavIcon>
              <FontAwesomeIcon icon={faPlus} />
            </NavIcon>
            Add Product
          </NavItem>
          <NavItem to="/wallet">
            <NavIcon>
              <FontAwesomeIcon icon={faWallet} />
            </NavIcon>
            Wallet
          </NavItem>
          <NavItem to="/my-listing">
            <NavIcon>
              <FontAwesomeIcon icon={faList} />
            </NavIcon>
            My Listing
          </NavItem>
          <NavItem to="/profile">
            <NavIcon>
              <FontAwesomeIcon icon={faUser} />
            </NavIcon>
            Profile
          </NavItem>
        </BottomNavigation>
      </MainContainer>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377e62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: normal;
  color: white;
`;

const MainContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const TopUpSection = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px; 
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const SectionTitle = styled.h3`
  font-size: 1.2em;
  color: #333;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  color: #377e62;
  cursor: help;
  font-size: 0.9em;
`;

const ConversionInfoBox = styled.div`
  position: absolute;
  top: 50px;
  right: 20px;
  background: #377e62;
  color: white;
  padding: 15px;
  border-radius: 8px;
  font-size: 0.9em;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  line-height: 1.6;

  &::before {
    content: '';
    position: absolute;
    top: -6px;
    right: 20px;
    width: 12px;
    height: 12px;
    background: #377e62;
    transform: rotate(45deg);
  }
`;

const BalanceDisplay = styled.h2`
  color: #377e62;
  text-align: center;
  font-size: 1.8em;
  margin: 20px 0;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const CurrencyInputContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 12px;
  font-weight: 500;
  color: #333;
  text-align: left;
  width: 100%;
`;

const AmountInput = styled.input`
  width: 90%;
  padding: 20px 24px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
`;

const CurrencyLabel = styled.span`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  font-weight: 500;
  pointer-events: none;
`;

const CostPreview = styled.div`
  margin: 10px 0 20px;
  color: #377e62;
  font-weight: 500;
  font-size: 1.1em;
  padding: 10px 15px;
  background: #f0f7f4;
  border-radius: 6px;
  width: 100%;
  text-align: center;
`;

const TopUpButton = styled.button`
  width: 100%;
  padding: 20px;
  border: none;
  border-radius: 8px;
  background-color: #cccccc;
  color: white;

  &:not(:disabled) {
    background-color: #377e62;
    cursor: pointer;
  }
`;

const Divider = styled.div`
  text-align: center;
  margin: 30px 0;
  position: relative;
  color: #666;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background-color: #e0e0e0;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
`;

const ErrorMessage = styled.div`
  color: #d32f2f;
  background: #fde7e7;
  padding: 12px;
  border-radius: 8px;
  margin: 10px 0;
  text-align: center;
  width: 100%;
`;

const SuccessMessage = styled.div`
  color: #2e7d32;
  background: #edf7ed;
  padding: 12px;
  border-radius: 8px;
  margin: 10px 0;
  text-align: center;
  width: 100%;
`;

const TransactionHistory = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Transaction = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  padding: 12px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const TransactionDescription = styled.span`
  text-align: left;
  font-weight: 500;
  color: #333;
`;

const TransactionAmount = styled.span`
  text-align: right;
  color: ${props => props.positive ? '#2e7d32' : '#d32f2f'};
  font-weight: 600;
`;

const TransactionDate = styled.span`
  font-size: 0.85em;
  color: #666;
  text-align: right;
`;

const NoTransactions = styled.p`
  color: #666;
  font-style: italic;
  text-align: center;
  padding: 20px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

const PageButton = styled.button`
  background-color: #377e62;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover:not(:disabled) {
    background-color: #2c6550;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const PageInfo = styled.span`
  color: #666;
  font-size: 0.9em;
`;

const BottomNavigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;
  transition: color 0.2s;

  &:hover {
    color: #377e62;
  }
`;

const NavIcon = styled.span`
  font-size: 24px;
  margin-bottom: 4px;
`;

export default WalletScreen;