import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
  color: #333;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
`;

const InstallButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const BackButton = styled.button`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  padding: 10px 20px;
  background-color: #377E62;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const Instructions = styled.div`
  margin-top: 30px;
  text-align: left;
`;

const Input = styled.input`
  font-size: 16px;
  padding: 10px;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const InstallationPage = () => {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /Android/.test(navigator.userAgent);

  const handleInstall = (platform) => {
    if (platform === 'android') {
      if (window.deferredPrompt) {
        window.deferredPrompt.prompt();
        window.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
          window.deferredPrompt = null;
        });
      } else {
        alert('To install, open this page in Chrome and tap "Add to Home screen".');
      }
    } else if (platform === 'ios') {
      alert('To install on iOS, tap the share button and then "Add to Home Screen".');
    }
  };

  const handleFocus = (event) => {
    event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleBackClick = () => {
    window.location.href = 'https://app.keboon.net';
  };

  return (
    <PageContainer>
      <Title>Install Keboon App</Title>
      <ButtonContainer>
        <InstallButton onClick={() => handleInstall('android')} style={{ display: isAndroid ? 'inline-block' : 'none' }}>
          Install for Android
        </InstallButton>
        <InstallButton onClick={() => handleInstall('ios')} style={{ display: isIOS ? 'inline-block' : 'none' }}>
          Install for iOS
        </InstallButton>
      </ButtonContainer>
      
      <BackButton onClick={handleBackClick}>
        Return to Keboon App
      </BackButton>
      
      <Instructions>
        <h2>Installation Instructions:</h2>
        <h3>For Android:</h3>
        <ol>
          <li>Open this page in Chrome.</li>
          <li>Tap the "Install for Android" button above.</li>
          <li>If prompted, tap "Add to Home screen".</li>
        </ol>
        <h3>For iOS:</h3>
        <ol>
          <li>Open this page in Safari.</li>
          <li>Tap the share button at the bottom of the browser.</li>
          <li>Scroll down and tap "Add to Home Screen".</li>
          <li>Tap "Add" in the top right corner.</li>
        </ol>
      </Instructions>
    </PageContainer>
  );
};

export default InstallationPage;