import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  color: #377e62;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: ${(props) => (props.active ? "#377E62" : "#f0f0f0")};
  color: ${(props) => (props.active ? "white" : "black")};
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${(props) => (props.active ? "#2c6a52" : "#e0e0e0")};
  }
`;

const ProductCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  gap: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ProductImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.h3`
  margin: 0 0 10px 0;
  color: #377e62;
`;

const ProductPrice = styled.p`
  margin: 0 0 10px 0;
  font-weight: bold;
`;

const LocationLink = styled.p`
  color: #377e62;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ProductContact = styled.a`
  color: #377e62;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const Button = styled.button`
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #377e62;
  color: white;
  &:hover {
    background-color: #2c6a52;
  }
`;

const ApproveButton = styled(Button)`
  background-color: #4caf50;
  &:hover {
    background-color: #45a049;
  }
`;

const RejectButton = styled(Button)`
  background-color: #f44336;
  &:hover {
    background-color: #da190b;
  }
`;

const LogoutButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #d32f2f;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  &:focus {
    outline: none;
    border-color: #377e62;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #f2f2f2;
  border-bottom: 2px solid #ddd;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
`;

const ErrorMessage = styled.p`
  color: #f44336;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: #4caf50;
  margin-top: 10px;
`;

const InfoText = styled.p`
  color: #666;
  margin-bottom: 15px;
  font-size: 0.9em;
`;

const PriceReferenceContainer = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  border-left: 4px solid
    ${(props) => {
      switch (props.status) {
        case "below":
          return "#2196F3"; // Blue
        case "within":
          return "#4CAF50"; // Green
        case "above":
          return "#f44336"; // Red
        default:
          return "#757575"; // Grey
      }
    }};
`;

const PriceReferenceTitle = styled.h4`
  margin: 0 0 8px 0;
  color: #333;
  font-size: 14px;
`;

const PriceReferenceInfo = styled.p`
  margin: 4px 0;
  font-size: 13px;
  color: #666;
`;

const PriceStatus = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: ${(props) => {
    switch (props.status) {
      case "below":
        return "#2196F3"; // Blue
      case "within":
        return "#4CAF50"; // Green
      case "above":
        return "#f44336"; // Red
      default:
        return "#757575"; // Grey
    }
  }};
`;

function AdminDashboard() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("products");
  const [pendingProducts, setPendingProducts] = useState([]);
  const [topUpCodes, setTopUpCodes] = useState([]);
  const [newTopUpCode, setNewTopUpCode] = useState({
    code: "",
    validFrom: "",
    validTo: "",
    entitledUsers: "",
    usageLimit: "",
    amount: "",
    isPublic: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [postPrice, setPostPrice] = useState("");

  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    if (!isAdmin) {
      navigate("/login");
    } else {
      fetchPendingProducts();
      fetchTopUpCodes();
      fetchPostPrice();
    }
  }, [navigate]);

  const fetchPendingProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/api/admin/pending-products", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setPendingProducts(response.data.products);
    } catch (error) {
      console.error("Error fetching pending products:", error);
      setError("Failed to fetch pending products. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTopUpCodes = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/api/admin/topup-codes", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setTopUpCodes(response.data);
    } catch (error) {
      console.error("Error fetching top-up codes:", error);
      setError("Failed to fetch top-up codes. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPostPrice = async () => {
    try {
      const response = await axios.get("/api/post-price");
      // Convert RM to Boons
      setPostPrice((response.data.price * 100).toString());
    } catch (error) {
      console.error("Error fetching post price:", error);
      setError("Failed to fetch post price. Please try again.");
    }
  };

  const handleApprove = async (productId) => {
    try {
      await axios.post(
        `/api/admin/approve-product/${productId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      fetchPendingProducts();
      setSuccessMessage("Product approved successfully");
    } catch (error) {
      console.error("Error approving product:", error);
      setError("Failed to approve product. Please try again.");
    }
  };

  const handleReject = async (productId) => {
    try {
      await axios.post(
        `/api/admin/reject-product/${productId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      fetchPendingProducts();
      setSuccessMessage("Product rejected successfully");
    } catch (error) {
      console.error("Error rejecting product:", error);
      setError("Failed to reject product. Please try again.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("isAdmin");
    navigate("/login");
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "amount") {
      // Ensure amount is always a whole number for Boons
      const numValue = parseInt(value) || "";
      setNewTopUpCode((prevState) => ({
        ...prevState,
        [name]: numValue,
      }));
    } else {
      setNewTopUpCode((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmitTopUpCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccessMessage("");

    try {
      const codeData = {
        ...newTopUpCode,
        // Convert Boons to RM for API
        amount: parseFloat(newTopUpCode.amount) / 100,
        entitledUsers: newTopUpCode.isPublic
          ? []
          : newTopUpCode.entitledUsers.split(",").map((email) => email.trim()),
      };

      await axios.post("/api/admin/topup-code", codeData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      setSuccessMessage("Top-up code created successfully!");
      setNewTopUpCode({
        code: "",
        validFrom: "",
        validTo: "",
        entitledUsers: "",
        usageLimit: "",
        amount: "",
        isPublic: false,
      });
      fetchTopUpCodes();
    } catch (error) {
      console.error("Error creating top-up code:", error);
      setError(
        error.response?.data?.message ||
          "Failed to create top-up code. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTopUpCode = async (codeId) => {
    if (window.confirm("Are you sure you want to delete this top-up code?")) {
      try {
        await axios.delete(`/api/admin/topup-code/${codeId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        setSuccessMessage("Top-up code deleted successfully");
        fetchTopUpCodes();
      } catch (error) {
        console.error("Error deleting top-up code:", error);
        setError("Failed to delete top-up code. Please try again.");
      }
    }
  };

  const handlePostPriceChange = (e) => {
    // Ensure post price is always a whole number for Boons
    const value = parseInt(e.target.value) || "";
    setPostPrice(value.toString());
  };

  const handleUpdatePostPrice = async () => {
    try {
      // Convert Boons back to RM for API
      await axios.post(
        "/api/update-post-price",
        { price: parseFloat(postPrice) / 100 },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setSuccessMessage("Post price updated successfully");
    } catch (error) {
      console.error("Error updating post price:", error);
      setError("Failed to update post price. Please try again.");
    }
  };

  const handleLocationClick = (lat, lon) => {
    window.open(`https://www.google.com/maps?q=${lat},${lon}`);
  };

  return (
    <Container>
      <Title>Admin Dashboard</Title>
      <LogoutButton onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} /> Logout
      </LogoutButton>

      <TabContainer>
        <Tab
          active={activeTab === "products"}
          onClick={() => setActiveTab("products")}
        >
          Pending Products
        </Tab>
        <Tab
          active={activeTab === "topUpCodes"}
          onClick={() => setActiveTab("topUpCodes")}
        >
          Top-up Codes
        </Tab>
        <Tab
          active={activeTab === "postPrice"}
          onClick={() => setActiveTab("postPrice")}
        >
          Post Price
        </Tab>
      </TabContainer>

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}

      {activeTab === "products" && (
        <>
          <h2>Pending Products</h2>
          {isLoading ? (
            <p>Loading pending products...</p>
          ) : pendingProducts.length > 0 ? (
            pendingProducts.map((product) => (
              <ProductCard key={product._id}>
                <ProductImage src={product.image} alt={product.name} />
                <ProductInfo>
                  <ProductName>{product.name}</ProductName>
                  <ProductPrice>
                    RM {product.price.toFixed(2)}/{product.unit}
                  </ProductPrice>
                  <LocationLink
                    onClick={() =>
                      handleLocationClick(
                        product.location.lat,
                        product.location.lon
                      )
                    }
                  >
                    {product.locationDetail}
                  </LocationLink>

                  {product.priceReference && (
                    <PriceReferenceContainer
                      status={product.priceReference.status}
                    >
                      <PriceReferenceTitle>
                        Price Reference: {product.priceReference.name}
                      </PriceReferenceTitle>
                      <PriceReferenceInfo>
                        Market Price: RM{" "}
                        {product.priceReference.meanPrice.toFixed(2)}/
                        {product.priceReference.unit}
                      </PriceReferenceInfo>
                      <PriceReferenceInfo>
                        Range: RM {product.priceReference.min.toFixed(2)} - RM{" "}
                        {product.priceReference.max.toFixed(2)}
                      </PriceReferenceInfo>
                      <PriceReferenceInfo>
                        Converted Price: RM{" "}
                        {product.priceReference.convertedPrice.toFixed(2)}/
                        {product.priceReference.unit}
                      </PriceReferenceInfo>
                      <PriceReferenceInfo>
                        Status:{" "}
                        <PriceStatus status={product.priceReference.status}>
                          {product.priceReference.status.toUpperCase()}
                        </PriceStatus>
                      </PriceReferenceInfo>
                    </PriceReferenceContainer>
                  )}

                  <ButtonGroup>
                    <ApproveButton onClick={() => handleApprove(product._id)}>
                      Approve
                    </ApproveButton>
                    <RejectButton onClick={() => handleReject(product._id)}>
                      Reject
                    </RejectButton>
                  </ButtonGroup>
                </ProductInfo>
              </ProductCard>
            ))
          ) : (
            <p>No pending products found.</p>
          )}
        </>
      )}

      {activeTab === "topUpCodes" && (
        <>
          <h2>Create Top-up Code</h2>
          <InfoText>Enter amounts in Boons (1 RM = 100 Boons)</InfoText>
          <Form onSubmit={handleSubmitTopUpCode}>
            <Input
              name="code"
              value={newTopUpCode.code}
              onChange={handleInputChange}
              placeholder="Code"
              required
            />
            <Input
              name="validFrom"
              type="datetime-local"
              value={newTopUpCode.validFrom}
              onChange={handleInputChange}
              required
            />
            <Input
              name="validTo"
              type="datetime-local"
              value={newTopUpCode.validTo}
              onChange={handleInputChange}
              required
            />
            <Input
              name="usageLimit"
              type="number"
              value={newTopUpCode.usageLimit}
              onChange={handleInputChange}
              placeholder="Usage Limit"
              min="1"
              required
            />
            <Input
              name="amount"
              type="number"
              value={newTopUpCode.amount}
              onChange={handleInputChange}
              placeholder="Amount (in Boons)"
              min="1"
              required
            />
            <CheckboxLabel>
              <input
                type="checkbox"
                name="isPublic"
                checked={newTopUpCode.isPublic}
                onChange={handleInputChange}
              />
              Public Code (available to all users)
            </CheckboxLabel>
            {!newTopUpCode.isPublic && (
              <Input
                name="entitledUsers"
                value={newTopUpCode.entitledUsers}
                onChange={handleInputChange}
                placeholder="Entitled User Emails (comma-separated)"
              />
            )}
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Creating..." : "Create Top-up Code"}
            </Button>
          </Form>

          <h3>Existing Top-up Codes</h3>
          {isLoading ? (
            <p>Loading top-up codes...</p>
          ) : topUpCodes.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <Th>Code</Th>
                  <Th>Valid From</Th>
                  <Th>Valid To</Th>
                  <Th>Usage Limit</Th>
                  <Th>Amount</Th>
                  <Th>Public</Th>
                  <Th>Entitled Users</Th>
                  <Th>Actions</Th>
                </tr>
              </thead>
              <tbody>
                {topUpCodes.map((code) => (
                  <tr key={code._id}>
                    <Td>{code.code}</Td>
                    <Td>{new Date(code.validFrom).toLocaleString()}</Td>
                    <Td>{new Date(code.validTo).toLocaleString()}</Td>
                    <Td>{code.usageLimit}</Td>
                    <Td>{(code.amount * 100).toFixed(0)} Boons</Td>
                    <Td>{code.isPublic ? "Yes" : "No"}</Td>
                    <Td>
                      {code.isPublic
                        ? "All Users"
                        : code.entitledUsers
                            .map((user) => user.email)
                            .join(", ")}
                    </Td>
                    <Td>
                      <Button onClick={() => handleDeleteTopUpCode(code._id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No top-up codes found.</p>
          )}
        </>
      )}

      {activeTab === "postPrice" && (
        <>
          <h2>Set Post Price</h2>
          <InfoText>Enter the post price in Boons (1 RM = 100 Boons)</InfoText>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdatePostPrice();
            }}
          >
            <Input
              type="number"
              value={postPrice}
              onChange={handlePostPriceChange}
              placeholder="Post Price (in Boons)"
              min="1"
              required
            />
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Updating..." : "Update Post Price"}
            </Button>
          </Form>
        </>
      )}
    </Container>
  );
}

export default AdminDashboard;
