import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f8f0;
`;

const ResetContainer = styled.div`
  width: 90%;
  max-width: 400px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  background-color: white;
  @media (max-width: 768px) {
    width: 85%;
    padding: 20px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 5px;
  color: #2c3e50;
  text-align: center;
`;

const Subtitle = styled.p`
  color: #666;
  margin-bottom: 20px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
`;

const Input = styled.input`
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const Button = styled.button`
  width: 90%;
  padding: 10px;
  background-color: #377E62;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #45a049;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const Error = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

const Message = styled.p`
  color: green;
  text-align: center;
  margin-bottom: 10px;
`;

const Spinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-right: 10px;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

function ResetPasswordPage() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');
  const [apiUrl, setApiUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const fullUrl = `${window.location.origin}/api/config`;
        console.log('Fetching config from:', fullUrl);
        const response = await axios.get(fullUrl);
        console.log('Config response:', response.data);
        setApiUrl(response.data.backendUrl);
      } catch (error) {
        console.error('Error fetching API config:', error);
        setError('Unable to connect to the server. Please try again later.');
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const resetToken = queryParams.get('token');
    if (resetToken) {
      setToken(resetToken);
    } else {
      setError('Invalid or missing reset token.');
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    setIsLoading(true);
  
    if (!token) {
      setError('Invalid or expired reset token.');
      setIsLoading(false);
      return;
    }
  
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setIsLoading(false);
      return;
    }
  
    if (!apiUrl) {
      setError('Server configuration is not available. Please try again later.');
      setIsLoading(false);
      return;
    }

    try {
      const updatePasswordUrl = `${apiUrl}/api/update-password`;
      console.log('Sending update password request to:', updatePasswordUrl);
      const response = await axios.post(
        updatePasswordUrl,
        { newPassword: password },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      console.log('Password update response:', response.data);
      setMessage(response.data.message || 'Password updated successfully');
      setIsSuccess(true);
      setTimeout(() => navigate('/login'), 3000);
    } catch (err) {
      console.error('Update password error:', err);
      setError(err.response?.data?.message || 'Error resetting password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageContainer>
      <ResetContainer>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Title>Keboon.</Title>
        </Link>
        <Subtitle>Reset Your Password</Subtitle>
        {error && <Error>{error}</Error>}
        {message && <Message>{message}</Message>}
        <Form onSubmit={handleSubmit}>
          <InputWrapper>
            <Input
              type="password"
              placeholder="Enter new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="password"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
          </InputWrapper>
          <Button type="submit" disabled={isLoading || isSuccess}>
            {isLoading ? (
              <>
                <Spinner />
                Resetting...
              </>
            ) : isSuccess ? (
              'Password Reset!'
            ) : (
              'Reset Password'
            )}
          </Button>
        </Form>
      </ResetContainer>
    </PageContainer>
  );
}

export default ResetPasswordPage;