import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faUser, faList, faPlus, faHome } from '@fortawesome/free-solid-svg-icons';
import logo from './icons/keboon-logo-white.png';

const PageWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
  background-color: white;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377E62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const BottomNavigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;

  &:hover {
    color: #377E62;
  }
`;

const NavIcon = styled.span`
  font-size: 24px;
  margin-bottom: 4px;
`;

const ContentContainer = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  color: #377E62;
  text-align: center;
`;

const Subtitle = styled.p`
  text-align: center;
  font-size: 18px;
  color: #666;
  margin: 40px 0;
`;

function KeboonSupplies() {
  return (
    <PageWrapper>
      <Header>
        <Logo src={logo} alt="Keboon Logo" />
      </Header>
      <MainContainer>
        <ContentContainer>
          <Title>Keboon Supplies</Title>
          <Subtitle>Your go-to destination for all your gardening supplies — Coming Soon!</Subtitle>
          {/* Add content for Keboon Supplies here */}
        </ContentContainer>
      </MainContainer>
      <BottomNavigation>
        <NavItem to="/main">
          <NavIcon><FontAwesomeIcon icon={faHome} /></NavIcon>
          Home
        </NavItem>
        <NavItem to="/add-product">
          <NavIcon><FontAwesomeIcon icon={faPlus} /></NavIcon>
          Add Product
        </NavItem>
        <NavItem to="/wallet">
          <NavIcon><FontAwesomeIcon icon={faWallet} /></NavIcon>
          Wallet
        </NavItem>
        <NavItem to="/my-listing">
          <NavIcon><FontAwesomeIcon icon={faList} /></NavIcon>
          My Listing
        </NavItem>
        <NavItem to="/profile">
          <NavIcon><FontAwesomeIcon icon={faUser} /></NavIcon>
          Profile
        </NavItem>
      </BottomNavigation>
    </PageWrapper>
  );
}

export default KeboonSupplies;