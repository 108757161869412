import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { LoadScript } from '@react-google-maps/api';
//import * as serviceWorkerRegistration from './serviceWorker';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

ReactDOM.render(
  <React.StrictMode>
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <App />
    </LoadScript>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();