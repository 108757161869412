import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWallet,
  faUser,
  faList,
  faPlus,
  faHome,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import logo from "./icons/keboon-logo-white.png";

const ProductItem = styled.div`
  position: relative; // Add this line
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-bottom: 15px;
`;

const ProductContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  color: #377e62;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  display: flex;
  align-items: center;
`;

const ExpandedContent = styled.div`
  padding: 15px;
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
`;

const InterestedUsersList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const InterestedUserItem = styled.li`
  margin-bottom: 20px;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const BuyerInfo = styled.p`
  margin: 5px 0;
  font-size: 14px;
`;

const StatusLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: ${(props) =>
    props.status === "pending"
      ? "#FFA500"
      : props.status === "rejected"
      ? "#FF0000"
      : "inherit"};
`;

const PageWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 60px;
  background-color: white;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377e62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: normal;
  color: white;
`;

const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ProductName = styled.h3`
  margin: 0;
  font-size: 18px;
  color: #333;
`;

const ProductPrice = styled.p`
  margin: 5px 0;
  color: #52ae77;
`;

const BottomNavigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;

  &:hover {
    color: #377e62;
  }
`;

const NavIcon = styled.span`
  font-size: 24px;
  margin-bottom: 4px;
`;

const SoldOutButton = styled.button`
  padding: 8px 12px;
  background-color: ${(props) => (props.isSoldOut ? "#f44336" : "#377E62")};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: ${(props) => (props.isLoading ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.isLoading ? 0.7 : 1)};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.isSoldOut ? "#d32f2f" : "#52AE77")};
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  color: #377e62;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: ${(props) => (props.active ? "#377E62" : "white")};
  color: ${(props) => (props.active ? "white" : "#333")};
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.active ? "#377E62" : "#f0f0f0")};
  }
`;

const EmptyListMessage = styled.p`
  text-align: center;
  font-size: 18px;
  color: #666;
  margin: 40px 0;
`;

const StatusDropdown = styled.select`
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  color: white;
  margin-left: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;

  background-color: ${(props) => {
    switch (props.value) {
      case "done":
        return "#4CAF50";
      case "in_progress":
        return "#2196F3";
      default:
        return "#377E62";
    }
  }};

  &:focus {
    outline: none;
    border-color: ${(props) => {
      switch (props.value) {
        case "done":
          return "#45a049";
        case "in_progress":
          return "#1976D2";
        default:
          return "#52AE77";
      }
    }};
  }

  option {
    background-color: white;
    color: #333;
  }
`;

const BuyerStatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  strong {
    color: #666;
  }
`;

const NewBuyerTag = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 4px;
`;

const PulsingDot = styled.span`
  width: 8px;
  height: 8px;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 6px rgba(255, 0, 0, 0);
    }
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }
`;

function UserProductsListPage() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProductId, setLoadingProductId] = useState(null);
  const [expandedProductId, setExpandedProductId] = useState(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;

  useEffect(() => {
    const fetchUserProducts = async () => {
      try {
        console.log("Fetching user products...");
        const response = await axios.get("/api/user/products", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        console.log("Fetched products:", response.data);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching user products:", error);
        if (error.response && error.response.status === 401) {
          navigate("/login");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProducts();
  }, [navigate]);

  const handleSoldOutToggle = async (productId, currentSoldOutStatus) => {
    setLoadingProductId(productId);
    try {
      const response = await axios.post(
        `/api/products/${productId}/sold-out`,
        { soldOut: !currentSoldOutStatus },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product._id === productId
            ? { ...product, soldOut: response.data.soldOut }
            : product
        )
      );
    } catch (error) {
      console.error("Error toggling sold out status:", error);
    } finally {
      setLoadingProductId(null);
    }
  };

  const handleExpand = async (productId) => {
    if (expandedProductId === productId) {
      setExpandedProductId(null);
    } else {
      setExpandedProductId(productId);
      if (!products.find((p) => p._id === productId).interestedBuyers) {
        try {
          const response = await axios.get(
            `/api/products/${productId}/interested-buyers`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          );
          setProducts((prevProducts) =>
            prevProducts.map((product) =>
              product._id === productId
                ? {
                    ...product,
                    interestedBuyers: response.data.interestedBuyers,
                  }
                : product
            )
          );
        } catch (error) {
          console.error("Error fetching interested buyers:", error);
        }
      }
    }
  };

  const renderProductAction = (product) => {
    if (product.status === "pending" || product.status === "rejected") {
      return (
        <StatusLabel status={product.status}>
          {product.status.charAt(0).toUpperCase() + product.status.slice(1)}
        </StatusLabel>
      );
    }
    return (
      <SoldOutButton
        isSoldOut={product.soldOut}
        isLoading={loadingProductId === product._id}
        onClick={() => handleSoldOutToggle(product._id, product.soldOut)}
        disabled={loadingProductId === product._id}
      >
        {loadingProductId === product._id
          ? "Updating..."
          : product.soldOut
          ? "Mark as Available"
          : "Mark as Sold Out"}
      </SoldOutButton>
    );
  };

  const handleBuyerStatusChange = async (productId, buyerId, newStatus) => {
    try {
      const response = await axios.post(
        `/api/products/${productId}/interested-buyers/${buyerId}/status`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product._id === productId
            ? {
                ...product,
                interestedBuyers: product.interestedBuyers.map((buyer) =>
                  buyer._id === buyerId
                    ? { ...buyer, orderStatus: newStatus }
                    : buyer
                ),
              }
            : product
        )
      );
    } catch (error) {
      console.error("Error updating buyer status:", error);
    }
  };

  const renderInterestedBuyers = (product) => {
    try {
      console.log("Rendering interested buyers for product:", product);

      if (!product.interestedBuyers || product.interestedBuyers.length === 0) {
        return <p>No interested buyers yet.</p>;
      }

      return (
        <InterestedUsersList>
          {product.interestedBuyers.map((buyer, index) => (
            <InterestedUserItem key={buyer._id || index}>
              <BuyerInfo>
                <strong>Name:</strong> {buyer.name || "N/A"}
              </BuyerInfo>
              <BuyerInfo>
                <strong>Mobile:</strong> {buyer.mobileNumber || "N/A"}
              </BuyerInfo>
              <BuyerInfo>
                <strong>Quantity:</strong> {buyer.quantity || "N/A"}
              </BuyerInfo>
              <BuyerInfo>
                <strong>Total Price:</strong>
                {buyer.totalPrice !== undefined
                  ? `RM${buyer.totalPrice.toFixed(2)}`
                  : "N/A"}
              </BuyerInfo>
              <BuyerInfo>
                <strong>Date:</strong>
                {buyer.date ? new Date(buyer.date).toLocaleString() : "N/A"}
              </BuyerInfo>
              <BuyerStatusContainer>
                <strong>Order Status:</strong>
                <StatusDropdown
                  value={buyer.orderStatus || "new"}
                  onChange={(e) =>
                    handleBuyerStatusChange(
                      product._id,
                      buyer._id,
                      e.target.value
                    )
                  }
                >
                  <option value="new">New Order</option>
                  <option value="in_progress">In Progress</option>
                  <option value="done">Done</option>
                </StatusDropdown>
              </BuyerStatusContainer>
            </InterestedUserItem>
          ))}
        </InterestedUsersList>
      );
    } catch (error) {
      console.error("Error in renderInterestedBuyers:", error);
      return <p>Error loading interested buyers. Please try again.</p>;
    }
  };

  // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const pageCount = Math.ceil(products.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <PageWrapper>
      <Header>
        <Logo src={logo} alt="Keboon Logo" />
        <Subtitle>My Products</Subtitle>
      </Header>
      <MainContainer>
        {isLoading ? (
          <LoadingMessage>Loading your products...</LoadingMessage>
        ) : products.length === 0 ? (
          <EmptyListMessage>
            You don't have any products listed yet. Start by adding a new
            product!
          </EmptyListMessage>
        ) : (
          <>
            <ProductList>
              {currentProducts.map((product) => (
                <ProductItem key={product._id}>
                  <ProductContent>
                    <ProductInfo>
                      {product.interestedBuyers?.some(
                        (buyer) => buyer.orderStatus === "new"
                      ) && (
                        <NewBuyerTag>
                          <PulsingDot />
                          <span style={{ color: "#ff0000", fontSize: "12px" }}>
                            New Buyer
                          </span>
                        </NewBuyerTag>
                      )}
                      <ProductName>{product.name}</ProductName>
                      <ProductPrice>
                        RM{product.price}/{product.unit}
                      </ProductPrice>
                    </ProductInfo>
                    <ProductActions>
                      {renderProductAction(product)}
                      {product.status !== "pending" &&
                        product.status !== "rejected" && (
                          <ExpandButton
                            onClick={() => handleExpand(product._id)}
                          >
                            <FontAwesomeIcon
                              icon={
                                expandedProductId === product._id
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                            {expandedProductId === product._id
                              ? "Hide Details"
                              : "Show Details"}
                          </ExpandButton>
                        )}
                    </ProductActions>
                  </ProductContent>
                  {expandedProductId === product._id &&
                    product.status !== "pending" &&
                    product.status !== "rejected" && (
                      <ExpandedContent>
                        <h4>Interested Buyers:</h4>
                        {renderInterestedBuyers(product)}
                      </ExpandedContent>
                    )}
                </ProductItem>
              ))}
            </ProductList>
            <PaginationContainer>
              {[...Array(pageCount)].map((_, i) => (
                <PageButton
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  active={currentPage === i + 1}
                >
                  {i + 1}
                </PageButton>
              ))}
            </PaginationContainer>
          </>
        )}
      </MainContainer>
      <BottomNavigation>
        <NavItem to="/main">
          <NavIcon>
            <FontAwesomeIcon icon={faHome} />
          </NavIcon>
          Home
        </NavItem>
        <NavItem to="/add-product">
          <NavIcon>
            <FontAwesomeIcon icon={faPlus} />
          </NavIcon>
          Add Product
        </NavItem>
        <NavItem to="/wallet">
          <NavIcon>
            <FontAwesomeIcon icon={faWallet} />
          </NavIcon>
          Wallet
        </NavItem>
        <NavItem to="/my-listing">
          <NavIcon>
            <FontAwesomeIcon icon={faList} />
          </NavIcon>
          My Listing
        </NavItem>
        <NavItem to="/profile">
          <NavIcon>
            <FontAwesomeIcon icon={faUser} />
          </NavIcon>
          Profile
        </NavItem>
      </BottomNavigation>
    </PageWrapper>
  );
}

export default UserProductsListPage;
