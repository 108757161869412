import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faUser, faList, faPlus, faHome, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from './icons/keboon-logo-white.png';

const PageWrapper = styled.div`
  padding-bottom: 60px;
`;

const MainContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #377E62;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
`;

const MapComponent = styled(MapContainer)`
  height: 300px;
  margin-bottom: 20px;
`;

const BottomNavigation = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 12px;

  &:hover {
    color: #377E62;
  }
`;

const NavIcon = styled.span`
  font-size: 24px;
  margin-bottom: 4px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 10px;
`;

const SuccessMessage = styled.p`
  color: green;
  margin-bottom: 10px;
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  color: #377E62;
`;

const locationIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  text-align: center;
`;

const PopupMessage = styled.p`
  margin-bottom: 20px;
  color: ${props => props.isError ? 'red' : 'green'};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #333;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`;

function EditProductPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [unit, setUnit] = useState('');
  const [locationDetail, setLocationDetail] = useState('');
  const [contact, setContact] = useState('');
  const [location, setLocation] = useState(null);
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [popupMessage, setPopupMessage] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/api/products/${id}`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
        });
        const productData = response.data;
        setProduct(productData);
        setName(productData.name);
        setPrice(productData.price);
        setUnit(productData.unit);
        setLocationDetail(productData.locationDetail);
        setContact(productData.contact);
        setLocation([productData.location.lat, productData.location.lon]);
        setImage(productData.image);
      } catch (error) {
        console.error('Error fetching product:', error);
        if (error.response && error.response.status === 401) {
          setPopupMessage('You are not authorized to edit this product.');
          setIsError(true);
          navigate('/login');
        } else if (error.response && error.response.status === 404) {
          setPopupMessage('Product not found.');
          setIsError(true);
        } else {
          setPopupMessage('An error occurred while fetching the product. Please try again.');
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchProduct();
  }, [id, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    // Check if any changes were made
    const hasChanges = 
      name !== product.name ||
      price !== product.price ||
      unit !== product.unit ||
      locationDetail !== product.locationDetail ||
      contact !== product.contact ||
      location[0] !== product.location.lat ||
      location[1] !== product.location.lon;

    if (!hasChanges) {
      setPopupMessage('No changes were made to the product.');
      setIsError(true);
      setIsUpdating(false);
      return;
    }

    try {
      await axios.put(`/api/products/${id}`, 
        { 
          name, 
          price, 
          unit, 
          locationDetail, 
          contact, 
          location: { lat: location[0], lon: location[1] }, 
          image
        },
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` } }
      );
      setPopupMessage('Product updated successfully!');
      setIsError(false);
    } catch (error) {
      console.error('Error updating product:', error);
      if (error.response && error.response.status === 401) {
        setPopupMessage('You are not authorized to update this product.');
      } else {
        setPopupMessage('An error occurred while updating the product. Please try again.');
      }
      setIsError(true);
    } finally {
      setIsUpdating(false);
    }
  };

  function MapEvents() {
    const map = useMap();
    map.on('click', (e) => {
      setLocation([e.latlng.lat, e.latlng.lng]);
    });
    return null;
  }

  const closePopup = () => {
    setPopupMessage(null);
    setIsError(false);
  };

  return (
    <PageWrapper>
      <MainContainer>
        <Header>
        <Logo src={logo} alt="Keboon Logo" />
          <h2>Edit Product</h2>
        </Header>
        {isLoading ? (
          <LoadingMessage>Loading product details...</LoadingMessage>
        ) : product ? (
          <Form onSubmit={handleSubmit}>
            <Input 
              type="text" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              placeholder="Product Name" 
              required 
            />
            <Input 
              type="number" 
              value={price} 
              onChange={(e) => setPrice(e.target.value)} 
              placeholder="Price" 
              required 
            />
            <Input 
              type="text" 
              value={unit} 
              onChange={(e) => setUnit(e.target.value)} 
              placeholder="Unit" 
              required 
            />
            <TextArea 
              value={locationDetail} 
              onChange={(e) => setLocationDetail(e.target.value)} 
              placeholder="Location Detail" 
              required 
            />
            <Input 
              type="tel" 
              value={contact} 
              onChange={(e) => setContact(e.target.value)} 
              placeholder="Contact" 
              required 
            />
            {location && (
              <MapComponent center={location} zoom={13}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={location} icon={locationIcon}>
                  <Popup>Product location</Popup>
                </Marker>
                <MapEvents />
              </MapComponent>
            )}
            <Button type="submit" disabled={isUpdating}>
              {isUpdating ? 'Updating...' : 'Update Product'}
            </Button>
          </Form>
        ) : (
          <div>Product not found</div>
        )}
      </MainContainer>
      <BottomNavigation>
        <NavItem to="/main">
          <NavIcon><FontAwesomeIcon icon={faHome} /></NavIcon>
          Home
        </NavItem>
        <NavItem to="/add-product">
          <NavIcon><FontAwesomeIcon icon={faPlus} /></NavIcon>
          Add Product
        </NavItem>
        <NavItem to="/wallet">
          <NavIcon><FontAwesomeIcon icon={faWallet} /></NavIcon>
          Wallet
        </NavItem>
        <NavItem to="/my-listing">
          <NavIcon><FontAwesomeIcon icon={faList} /></NavIcon>
          My Listing
        </NavItem>
        <NavItem to="/profile">
          <NavIcon><FontAwesomeIcon icon={faUser} /></NavIcon>
          Profile
        </NavItem>
      </BottomNavigation>
      {popupMessage && (
        <PopupContainer>
          <PopupContent>
            <CloseButton onClick={closePopup}>
              <FontAwesomeIcon icon={faTimes} />
            </CloseButton>
            <PopupMessage isError={isError}>{popupMessage}</PopupMessage>
            <Button onClick={closePopup}>OK</Button>
          </PopupContent>
        </PopupContainer>
      )}
    </PageWrapper>
  );
}

export default EditProductPage;