import React from 'react';
import LoginForm from '../components/LoginForm';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f8f0;
`;

function LoginPage() {
  const navigate = useNavigate();

  const handleLoginSuccess = (data) => {
    localStorage.setItem('authToken', data.token);
    localStorage.setItem('isAdmin', data.isAdmin);
    localStorage.setItem('userEmail', data.email);
    
    if (data.isAdmin) {
      navigate('/admin/approve');
    } else {
      navigate('/main');
    }
  };

  return (
    <PageContainer>
      <LoginForm onLoginSuccess={handleLoginSuccess} />
    </PageContainer>
  );
}

export default LoginPage;