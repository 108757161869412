import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import logo from '../pages/icons/keboon-writing.png';

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
`;

const SignUpContainer = styled.div`
  width: 90%;
  max-width: 400px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  background-color: white;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 85%;
    padding: 20px;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f8f0;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 5px;
  color: #2c3e50;
  text-align: center;
`;

const Subtitle = styled.p`
  color: #666;
  margin-bottom: 20px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
`;

const Input = styled.input`
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const Button = styled.button`
  width: 90%;
  padding: 10px;
  background-color: #377E62;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #45a049;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const LoginLink = styled.p`
  text-align: center;
  margin-top: 20px;
`;

const Error = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

const SuccessMessage = styled.p`
  color: green;
  text-align: center;
  margin-bottom: 10px;
`;

const FailureMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

const PasswordRequirementsWrapper = styled.div`
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
  width: 90%;
`;

const PasswordRequirementsTitle = styled.h4`
  color: #333;
  margin-bottom: 10px;
  font-size: 16px;
`;

const PasswordRequirements = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const RequirementItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
  color: ${props => props.met ? '#4caf50' : '#f44336'};
  transition: color 0.3s ease;

  &:before {
    content: ${props => props.met ? '"✓"' : '"✗"'};
    margin-right: 8px;
    font-weight: bold;
  }
`;

function SignUpForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [apiUrl, setApiUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [signUpStatus, setSignUpStatus] = useState(null);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const fullUrl = `${window.location.origin}/api/config`;
        console.log('Fetching config from:', fullUrl);
        const response = await axios.get(fullUrl);
        console.log('Config response:', response.data);
        setApiUrl(response.data.backendUrl);
      } catch (error) {
        console.error('Error fetching API config:', error);
        setError('Unable to connect to the server. Please try again later.');
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    setPasswordRequirements({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*]/.test(password)
    });
  }, [password]);

  const validatePassword = () => {
    return Object.values(passwordRequirements).every(requirement => requirement);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    setSignUpStatus(null);
    console.log('Attempting sign up with:', { email, password: '****' });
    
    if (!validatePassword()) {
      setError('Password does not meet all requirements.');
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }

    if (!apiUrl) {
      setError('Server configuration is not available. Please try again later.');
      setIsLoading(false);
      return;
    }

    try {
      console.log('Sending sign up request to:', `${apiUrl}/api/create-user`);
      const response = await axios.post(`${apiUrl}/api/create-user`, { email, password });
      console.log('Sign up response:', response.data);
      
      // Simulate a delay
      await new Promise(resolve => setTimeout(resolve, 1000));

      if (response.data && response.data.message === 'User created successfully') {
        console.log('Sign up successful');
        setSignUpStatus('success');
        setTimeout(() => {
          // Check for redirect in query params
          const searchParams = new URLSearchParams(location.search);
          const redirectPath = searchParams.get('redirect');
          
          if (redirectPath) {
            navigate(`/login?redirect=${redirectPath}`);
          } else {
            navigate('/login');
          }
        }, 1500); // Delay navigation to show success message
      } else {
        console.error('Sign up failed: Invalid response from server', response.data);
        setSignUpStatus('failure');
        setError('Sign up failed: Invalid response from server');
      }
    } catch (error) {
      console.error('Sign up error:', error);
      
      // Simulate a delay
      await new Promise(resolve => setTimeout(resolve, 1000));

      setSignUpStatus('failure');
      if (error.response) {
        console.error('Error response:', error.response.data);
        setError(error.response.data.message || 'Sign up failed. Please try again.');
      } else if (error.request) {
        console.error('No response received:', error.request);
        setError('No response from server. Please check your internet connection and try again.');
      } else {
        console.error('Error', error.message);
        setError('An unexpected error occurred. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageContainer>
      <SignUpContainer>
        <Link to="/" style={{ textDecoration: 'none' }}>
         <Logo src={logo} alt="Keboon Logo" />
        </Link>
        <Subtitle>Please Sign Up</Subtitle>
        {error && <Error>{error}</Error>}
        {signUpStatus === 'success' && <SuccessMessage>Sign up successful! Redirecting to login...</SuccessMessage>}
        {signUpStatus === 'failure' && <FailureMessage>Sign up failed. Please try again.</FailureMessage>}
        <Form onSubmit={handleSubmit}>
          <InputWrapper>
            <Input
              type="email"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isLoading}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={isLoading}
            />
          </InputWrapper>
          <PasswordRequirementsWrapper>
            <PasswordRequirementsTitle>Password Requirements:</PasswordRequirementsTitle>
            <PasswordRequirements>
              <RequirementItem met={passwordRequirements.length}>At least 8 characters long</RequirementItem>
              <RequirementItem met={passwordRequirements.uppercase}>At least one uppercase letter (A-Z)</RequirementItem>
              <RequirementItem met={passwordRequirements.lowercase}>At least one lowercase letter (a-z)</RequirementItem>
              <RequirementItem met={passwordRequirements.number}>At least one number (0-9)</RequirementItem>
              <RequirementItem met={passwordRequirements.special}>At least one special character (!@#$%^&*)</RequirementItem>
            </PasswordRequirements>
          </PasswordRequirementsWrapper>
          <InputWrapper>
            <Input
              type="password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              disabled={isLoading}
            />
          </InputWrapper>
          <Button type="submit" disabled={isLoading || !validatePassword()}>
            {isLoading ? 'Signing Up...' : 'Sign Up'}
          </Button>
        </Form>
        <LoginLink>
          Already have an account?{' '}
          <Link to={`/login${location.search}`} style={{ color: 'purple' }}>
            Log in
          </Link>
        </LoginLink>
      </SignUpContainer>
    </PageContainer>
  );
}

export default SignUpForm;