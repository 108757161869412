import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "./icons/keboon-logo-white.png";
import {
  faSearch,
  faHeart,
  faSpinner,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

const InterestedButton = styled.button`
  padding: 5px 10px;
  background-color: #52ae77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #377e62;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const SpinnerIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
`;

const ModalInput = styled.input`
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  background-color: #52ae77;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
`;

const PageWrapper = styled.div`
  padding-top: 80px;
  background-color: white;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #377e62;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: left;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const SearchBarWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
  box-sizing: border-box;
  font-size: 16px;
`;

const SearchIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #377e62;
`;

const SortDropdown = styled.select`
  padding: 10px;
  border: 0px solid #ddd;
  border-left: none;
  border-radius: 0 4px 4px 0;
  background-color: #f8f8f8;
  font-size: 16px;
  color: #377e62;
  cursor: pointer;
`;

const MapComponent = styled(MapContainer)`
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DateGroup = styled.div`
  margin-bottom: 20px;
`;

const DateHeader = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
  color: #377e62;
`;

const ProductCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.h3`
  margin: 0 0 5px 0;
  color: #333;
`;

const ProductPrice = styled.div`
  margin: 0 0 5px 0;
  font-weight: bold;
  color: #52ae77;
`;

const PriceComparisonText = styled.div`
  font-size: 12px;
  margin-top: 2px;
  color: ${(props) =>
    props.status === "above"
      ? "#FF5722" // Red for above market price
      : props.status === "within"
      ? "#4CAF50" // Green for within market price
      : "#4CAF50"}; // Green for below market price
`;

const MarketPriceInfo = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 2px;
`;

const LocationLink = styled.p`
  margin: 0 0 5px 0;
  color: #377e62;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const DistanceTag = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  color: #333;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: ${(props) => (props.active ? "#377E62" : "white")};
  color: ${(props) => (props.active ? "white" : "#333")};
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.active ? "#377E62" : "#f0f0f0")};
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  color: #377e62;
`;

const SoldOutTag = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #f44336;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
`;

const TotalPriceDisplay = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #377e62;
  margin-top: 10px;
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  width: max-content;
  max-width: 200px;
  display: none;
`;

const PriceInfoIcon = styled.span`
  position: relative;
  margin-left: 5px;
  cursor: help;

  &:hover ${Tooltip} {
    display: block;
  }
`;

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// Custom icons for user and product locations
const userIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const productIcon = new L.Icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const unitNormalization = {
  kg: [
    /\b(?:\d*\.?\d*\s*)?(?:kg|kgs|kilo|kilos|kilogram|kilograms|Kg|KG)\b/i,
    /±?\s*\d*\.?\d*\s*kg\/seekor/i,
  ],
  "100g": [
    /\b(?:\d*\.?\d*\s*)?(?:g|gram|grams|G)\b/i,
    /(?:±|\+\-)\s*\d*\.?\d*\s*g/i,
  ],
  L: [
    /\b(?:\d*\.?\d*\s*)?(?:l|L|liter|liters|litre|litres)\b/i,
    /\b\d*\.?\d*\s*(?:l|L|liter|liters|litre|litres)\b/i,
  ],
  mL: [
    /\b(?:\d*\.?\d*\s*)?(?:ml|mL|milliliter|milliliters|millilitre|millilitres)\b/i,
  ],
  piece: [
    /\b(?:\d*\.?\d*\s*)?(?:piece|pieces|pc|pcs|unit|units|biji|bijis|set|sets|sheet|sheets|ekor|ekors)\b/i,
    /\b(?:se|1)(?:biji|unit|set|naskah|pinggan|bungkus|mangkuk|ketul|piring|keping|gelas|batang|paket)\b/i,
  ],
};

// Base unit conversion factors
const baseConversions = {
  kg: {
    base: 1000, // base unit is grams
    "100g": 10,
  },
  "100g": {
    base: 100, // base unit is grams
    kg: 0.1,
  },
  L: {
    base: 1000, // base unit is milliliters
    mL: 1000,
  },
  mL: {
    base: 1, // base unit is milliliters
    L: 0.001,
  },
  piece: {
    base: 1,
  },
};

const extractNumericValue = (unitStr) => {
  if (!unitStr) return 1;

  // Remove any ± or +- symbols first
  const cleanStr = unitStr.replace(/[±\+\-]/g, "").trim();

  // Look for numeric values, including decimals
  const matches = cleanStr.match(/(\d*\.?\d+)/);

  // Return the numeric value or 1 if none found
  return matches ? parseFloat(matches[1]) : 1;
};

const normalizeUnit = (unit) => {
  if (!unit) return null;

  // Clean the input string
  const cleanUnit = unit.trim().toLowerCase();

  // Try to match against each normalized unit's patterns
  for (const [normalizedUnit, patterns] of Object.entries(unitNormalization)) {
    for (const pattern of patterns) {
      if (pattern.test(cleanUnit)) {
        return normalizedUnit;
      }
    }
  }

  return unit;
};

const stripNumericPrefix = (unit) => {
  // Remove any numeric prefix from the unit
  return unit.replace(/^\d+\s*/, "").trim();
};

const convertPrice = (price, fromUnit, toUnit) => {
  if (!price || !fromUnit || !toUnit) return price;

  // Get numeric multipliers from units
  const fromMultiplier = extractNumericValue(fromUnit);
  const toMultiplier = extractNumericValue(toUnit);

  // Normalize units
  const normalizedFromUnit = normalizeUnit(fromUnit);
  const normalizedToUnit = normalizeUnit(stripNumericPrefix(toUnit));

  if (!normalizedFromUnit || !normalizedToUnit) return price;

  // If units are the same type but different quantities (e.g., 1kg vs 10kg)
  if (normalizedFromUnit === normalizedToUnit) {
    return price * (fromMultiplier / toMultiplier);
  }

  // Get base conversion factor
  const conversionFactor =
    baseConversions[normalizedFromUnit]?.[normalizedToUnit];

  if (conversionFactor !== undefined) {
    // Convert price considering both the base conversion and the multipliers
    return price * conversionFactor * (fromMultiplier / toMultiplier);
  }

  // Try reverse conversion if direct conversion not found
  const reverseConversionFactor =
    baseConversions[normalizedToUnit]?.[normalizedFromUnit];

  if (reverseConversionFactor !== undefined) {
    return (
      price * (1 / reverseConversionFactor) * (fromMultiplier / toMultiplier)
    );
  }

  // If no conversion possible, return original price
  return price;
};

const formatConvertedPrice = (price, fromUnit, toUnit) => {
  // Convert units to base units (grams or milliliters)
  const getBaseAmount = (unit) => {
    const normalizedUnit = normalizeUnit(unit);
    const multiplier = extractNumericValue(unit);

    // Handle weight units
    if (unit.toLowerCase().includes("g") || normalizedUnit === "kg") {
      switch (normalizedUnit) {
        case "kg":
          return multiplier * 1000;
        case "100g":
          return multiplier * 100;
        default:
          return multiplier; // For units like "650g"
      }
    }

    // Handle volume units
    if (unit.toLowerCase().includes("l") || unit.toLowerCase().includes("ml")) {
      switch (normalizedUnit) {
        case "L":
          return multiplier * 1000;
        case "mL":
          return multiplier;
        default:
          // For units like "500ml"
          if (unit.toLowerCase().includes("ml")) {
            return multiplier;
          }
          // For units like "1.5l"
          return multiplier * 1000;
      }
    }

    return multiplier; // For pieces/units
  };

  const fromBaseAmount = getBaseAmount(fromUnit);
  const toBaseAmount = getBaseAmount(toUnit);

  // Convert price to target unit
  const converted = (price * toBaseAmount) / fromBaseAmount;

  // Handle very small numbers to prevent scientific notation
  if (converted < 0.01) {
    return `${converted.toFixed(4)}/${stripNumericPrefix(toUnit)}`;
  }
  return `${converted.toFixed(2)}/${stripNumericPrefix(toUnit)}`;
};

function MainAppPage() {
  const [showWhatsAppPopup, setShowWhatsAppPopup] = useState(false);
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");
  const [apiUrl] = useState("https://app.keboon.net/api");
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;
  const [mapCenter, setMapCenter] = useState([3.139003, 101.686855]); // Default center (Kuala Lumpur)
  const [userLocation, setUserLocation] = useState(null);
  const [sortBy, setSortBy] = useState("distance");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [interestedProductId, setInterestedProductId] = useState(null);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [quantity, setQuantity] = useState("");
  const [isInterestLoading, setIsInterestLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);

  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const [productsResponse, pricesResponse] = await Promise.all([
        axios.get(`${apiUrl}/products`),
        axios.get(
          "https://api-dot-keboon-ai-439616.as.r.appspot.com/api/average-prices"
        ),
      ]);

      const priceAnalysisData = pricesResponse.data;

      const productsWithAnalysis = productsResponse.data.map((product) => {
        // First try exact match
        let analysis = Object.entries(priceAnalysisData).find(
          ([name, data]) => name.toLowerCase() === product.name.toLowerCase()
        );

        // If no exact match, try partial match
        if (!analysis) {
          analysis = Object.entries(priceAnalysisData).find(
            ([name, data]) =>
              product.name.toLowerCase().includes(name.toLowerCase()) ||
              name.toLowerCase().includes(product.name.toLowerCase())
          );
        }

        if (analysis) {
          const [, priceData] = analysis;
          const normalizedProductUnit = normalizeUnit(product.unit);
          const normalizedPriceDataUnit = normalizeUnit(priceData.unit);

          // Convert both prices to base units for comparison
          const getBaseAmount = (price, unit) => {
            const multiplier = extractNumericValue(unit);
            const normalized = normalizeUnit(unit);

            if (unit.toLowerCase().includes("g") || normalized === "kg") {
              switch (normalized) {
                case "kg":
                  return price * multiplier * 1000;
                case "100g":
                  return price * multiplier * 100;
                default:
                  return price * multiplier;
              }
            }

            if (unit.toLowerCase().includes("l")) {
              if (unit.toLowerCase().includes("ml")) {
                return price * multiplier;
              }
              return price * multiplier * 1000;
            }

            return price * multiplier;
          };

          const baseProductPrice = getBaseAmount(product.price, product.unit);
          const baseReferencePrice = getBaseAmount(
            priceData.mean,
            priceData.unit
          );

          // Convert product price to reference unit for display
          const convertedPrice =
            baseProductPrice / getBaseAmount(1, priceData.unit);

          const status =
            baseProductPrice < baseReferencePrice * 0.8
              ? "below"
              : baseProductPrice > baseReferencePrice * 1.2
              ? "above"
              : "within";

          return {
            ...product,
            priceAnalysis: {
              status,
              meanPrice: priceData.mean,
              minPrice: priceData.min,
              maxPrice: priceData.max,
              referenceUnit: priceData.unit,
              convertedPrice,
              matched: true,
            },
          };
        }
        return {
          ...product,
          priceAnalysis: null,
        };
      });

      setProducts(productsWithAnalysis);
      if (userLocation) {
        setMapCenter([userLocation.latitude, userLocation.longitude]);
      } else if (
        productsWithAnalysis.length > 0 &&
        productsWithAnalysis[0].location
      ) {
        setMapCenter([
          productsWithAnalysis[0].location.lat,
          productsWithAnalysis[0].location.lon,
        ]);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  }, [apiUrl, userLocation]);

  useEffect(() => {
    fetchProducts();
    const intervalId = setInterval(fetchProducts, 1800000);
    return () => clearInterval(intervalId);
  }, [fetchProducts]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLoc = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          setUserLocation(userLoc);
          setMapCenter([userLoc.latitude, userLoc.longitude]);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const filteredAndSortedProducts = products
    .filter((product) =>
      product.name.toLowerCase().includes(search.toLowerCase())
    )
    .map((product) => ({
      ...product,
      distance:
        userLocation && product.location
          ? calculateDistance(
              userLocation.latitude,
              userLocation.longitude,
              product.location.lat,
              product.location.lon
            )
          : Infinity,
      priceComparison: product.priceAnalysis
        ? {
            difference: (
              ((product.price - product.priceAnalysis.meanPrice) /
                product.priceAnalysis.meanPrice) *
              100
            ).toFixed(1),
          }
        : null,
    }))
    .sort((a, b) => {
      if (sortBy === "distance") {
        return a.distance - b.distance;
      } else {
        return new Date(b.date) - new Date(a.date);
      }
    });

  const handleLocationClick = (lat, lon) => {
    window.open(`https://www.google.com/maps?q=${lat},${lon}`);
  };

  const handleInterested = (product) => {
    setInterestedProductId(product._id);
    setSelectedProduct(product);
    setQuantity("");
    setTotalPrice(0);
    setShowModal(true);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = e.target.value;
    setQuantity(newQuantity);
    if (selectedProduct && newQuantity !== "") {
      const calculatedTotal = selectedProduct.price * parseInt(newQuantity);
      setTotalPrice(isNaN(calculatedTotal) ? 0 : calculatedTotal);
    } else {
      setTotalPrice(0);
    }
  };

  const handleSubmitInterest = async () => {
    if (!name.trim() || !mobileNumber.trim() || !quantity.trim()) {
      alert("Please fill in all fields.");
      return;
    }

    const mobileRegex =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    if (!mobileRegex.test(mobileNumber)) {
      alert("Please enter a valid mobile number.");
      return;
    }

    const quantityNum = parseInt(quantity);
    if (isNaN(quantityNum) || quantityNum <= 0) {
      alert("Please enter a valid quantity.");
      return;
    }

    setIsInterestLoading(true);
    try {
      const payload = {
        name: name.trim(),
        mobileNumber: mobileNumber.trim(),
        quantity: quantityNum,
        totalPrice: totalPrice,
      };
      console.log("Sending interest payload:", payload);

      const response = await axios.post(
        `${apiUrl}/products/${interestedProductId}/interest`,
        payload
      );

      console.log("Interest submission response:", response.data);

      // If response includes seller's phone, show WhatsApp popup
      if (response.data.sellerPhone) {
        setSelectedProduct((prev) => ({
          ...prev,
          sellerPhone: response.data.sellerPhone,
        }));
        setShowWhatsAppPopup(true);
      } else {
        alert(
          response.data.message ||
            "You have expressed interest in this product. The seller will be notified."
        );
        setShowModal(false);
        setName("");
        setMobileNumber("");
        setQuantity("");
        setTotalPrice(0);
      }
    } catch (error) {
      console.error("Error expressing interest:", error);
      if (error.response && error.response.data) {
        alert(
          error.response.data.message ||
            "Failed to express interest. Please try again."
        );
      } else {
        alert(
          "An error occurred while sending your request. Please try again."
        );
      }
    } finally {
      setIsInterestLoading(false);
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredAndSortedProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const groupProductsByDate = (products) => {
    const groups = {};
    products.forEach((product) => {
      const date = new Date(product.date).toDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(product);
    });
    return groups;
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const groupedProducts = groupProductsByDate(currentProducts);

  const pageCount = Math.ceil(
    filteredAndSortedProducts.length / productsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function ChangeMapView({ center }) {
    const map = useMap();
    map.setView(center, map.getZoom());
    return null;
  }

  return (
    <PageWrapper>
      <Header>
        <Logo src={logo} alt="Keboon Logo" />
        <NavLinks>
          <NavLink href="https://app.keboon.net/installation">Install</NavLink>
          <NavLink href="/login">Sign In</NavLink>
          <NavLink href="/signup">Sign Up</NavLink>
        </NavLinks>
      </Header>
      <MainContainer>
        <SearchBarContainer>
          <SearchBarWrapper>
            <SearchBar
              type="text"
              placeholder="Search products"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon>
              <FontAwesomeIcon icon={faSearch} />
            </SearchIcon>
          </SearchBarWrapper>
          <SortDropdown value={sortBy} onChange={handleSortChange}>
            <option value="distance">Distance</option>
            <option value="date">Date</option>
          </SortDropdown>
        </SearchBarContainer>
        <MapComponent center={mapCenter} zoom={13}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <ChangeMapView center={mapCenter} />
          {userLocation && (
            <Marker
              position={[userLocation.latitude, userLocation.longitude]}
              icon={userIcon}
            >
              <Popup>You are here</Popup>
            </Marker>
          )}
          {filteredAndSortedProducts.map(
            (product) =>
              !product.soldOut &&
              product.location && (
                <Marker
                  key={product._id}
                  position={[product.location.lat, product.location.lon]}
                  icon={productIcon}
                >
                  <Popup>
                    <strong>{product.name}</strong>
                    <br />
                    Price: RM{product.price}/{product.unit}
                    <br />
                    Distance: {product.distance.toFixed(2)} km
                  </Popup>
                </Marker>
              )
          )}
        </MapComponent>

        {isLoading ? (
          <LoadingMessage>Loading products...</LoadingMessage>
        ) : (
          Object.entries(groupedProducts).map(([date, dateProducts]) => (
            <DateGroup key={date}>
              <DateHeader>{formatDate(date)}</DateHeader>
              {dateProducts.map((product) => (
                <ProductCard key={product._id}>
                  <ProductImage src={product.image} alt={product.name} />
                  <ProductInfo>
                    <ProductName>{product.name}</ProductName>
                    <ProductPrice>
                      RM{product.price}/{product.unit}
                    </ProductPrice>
                    {!product.soldOut && (
                      <>
                        <LocationLink
                          onClick={() =>
                            handleLocationClick(
                              product.location.lat,
                              product.location.lon
                            )
                          }
                        >
                          {product.locationDetail}
                        </LocationLink>
                        <InterestedButton
                          onClick={() => handleInterested(product)}
                          disabled={isInterestLoading}
                        >
                          {isInterestLoading ? (
                            <>
                              <SpinnerIcon icon={faSpinner} spin />
                              Loading...
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faHeart} /> Interested
                            </>
                          )}
                        </InterestedButton>
                      </>
                    )}
                    {product.distance !== Infinity && (
                      <DistanceTag>
                        {product.distance.toFixed(2)} km
                      </DistanceTag>
                    )}
                    {product.priceAnalysis && (
                      <>
                        <PriceComparisonText
                          status={
                            product.priceReference?.status ||
                            product.priceAnalysis?.status
                          }
                        >
                          {(product.priceReference?.status ||
                            product.priceAnalysis?.status) === "below" &&
                            "Below market price"}
                          {(product.priceReference?.status ||
                            product.priceAnalysis?.status) === "within" &&
                            "Market price"}
                          {(product.priceReference?.status ||
                            product.priceAnalysis?.status) === "above" &&
                            "Above market price"}
                        </PriceComparisonText>
                        <MarketPriceInfo>
                          Market Avg: RM
                          {product.priceAnalysis.meanPrice.toFixed(2)}/
                          {product.priceAnalysis.referenceUnit}
                          {normalizeUnit(product.unit) !==
                            normalizeUnit(
                              product.priceAnalysis.referenceUnit
                            ) && (
                            <div>
                              Your price (converted): RM
                              {product.priceAnalysis.convertedPrice.toFixed(2)}/
                              {product.priceAnalysis.referenceUnit}
                            </div>
                          )}
                        </MarketPriceInfo>
                        <PriceInfoIcon>
                          <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                          <Tooltip>
                            Market prices are based on average retail prices
                            across Malaysia.
                            {normalizeUnit(product.unit) !==
                              normalizeUnit(
                                product.priceAnalysis.referenceUnit
                              ) &&
                              ` Price has been converted from ${product.unit} to ${product.priceAnalysis.referenceUnit} for comparison.`}
                          </Tooltip>
                        </PriceInfoIcon>
                      </>
                    )}
                    {!product.priceAnalysis && (
                      <MarketPriceInfo>
                        <em>No market price reference available</em>
                      </MarketPriceInfo>
                    )}
                  </ProductInfo>
                  {product.soldOut && <SoldOutTag>Sold Out</SoldOutTag>}
                </ProductCard>
              ))}
            </DateGroup>
          ))
        )}

        {showModal && (
          <Modal>
            <ModalContent>
              <h3>Express Interest</h3>
              <ModalInput
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <ModalInput
                type="tel"
                placeholder="Your Mobile Number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              <ModalInput
                type="number"
                placeholder="Quantity"
                value={quantity}
                onChange={handleQuantityChange}
                min="1"
              />
              <p>
                Product Price: RM{selectedProduct?.price}/
                {selectedProduct?.unit}
              </p>
              <TotalPriceDisplay>
                Total Price: RM{totalPrice.toFixed(2)}
              </TotalPriceDisplay>
              <ModalButton
                onClick={handleSubmitInterest}
                disabled={isInterestLoading}
              >
                {isInterestLoading ? "Submitting..." : "Submit"}
              </ModalButton>
              <ModalButton onClick={() => setShowModal(false)}>
                Cancel
              </ModalButton>
            </ModalContent>
          </Modal>
        )}

        {showWhatsAppPopup && (
          <Modal>
            <ModalContent>
              <h3>Contact Seller via WhatsApp</h3>
              <p>Would you like to contact the seller on WhatsApp?</p>
              <ModalButton
                onClick={() => {
                  const message = encodeURIComponent(
                    `Hi! I'm interested in your Keboon product.\n\n` +
                      `I would like to order:\n` +
                      `Product: ${selectedProduct.name}\n` +
                      `Quantity: ${quantity} ${selectedProduct.unit}\n` +
                      `Total Price: RM${totalPrice.toFixed(2)}\n\n` +
                      `My name is ${name}. Is this still available?`
                  );

                  const formattedPhone = selectedProduct.sellerPhone.replace(
                    /\D/g,
                    ""
                  );
                  const phoneWithCountryCode = formattedPhone.startsWith("60")
                    ? formattedPhone
                    : "60" + formattedPhone;

                  window.open(
                    `https://wa.me/${phoneWithCountryCode}?text=${message}`,
                    "_blank"
                  );
                  setShowWhatsAppPopup(false);
                  setShowModal(false);
                  setName("");
                  setMobileNumber("");
                  setQuantity("");
                  setTotalPrice(0);
                }}
              >
                Yes
              </ModalButton>
              <ModalButton
                onClick={() => {
                  setShowWhatsAppPopup(false);
                  setShowModal(false);
                  setName("");
                  setMobileNumber("");
                  setQuantity("");
                  setTotalPrice(0);
                }}
              >
                No
              </ModalButton>
            </ModalContent>
          </Modal>
        )}

        <PaginationContainer>
          {[...Array(pageCount)].map((_, i) => (
            <PageButton
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              active={currentPage === i + 1}
            >
              {i + 1}
            </PageButton>
          ))}
        </PaginationContainer>
      </MainContainer>
    </PageWrapper>
  );
}

export default MainAppPage;
