import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CancelPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/wallet');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div>
      <h1>Payment Cancelled</h1>
      <p>Your payment was cancelled. Redirecting back to your wallet...</p>
    </div>
  );
};

export default CancelPage;